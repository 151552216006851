import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "../styles/global.scss"
import { useState } from "react"
import { Link } from "gatsby"
const Concerts = () => {
  const [navpopConcerts, setNavpopConcerts] = useState(false)
  const hamburgSecondary = (
      <svg
        onClick={() => setNavpopConcerts(!navpopConcerts)}
        className="hamburgSecondary"
      >
        <path
          d="M0.0864258 25.6278H48.5864"
          stroke="#F7F7F7"
          stroke-width="3"
        />
        <path
          id="middle-hamburg"
          d="M0 2H48.5"
          stroke="#F7F7F7"
          stroke-width="3"
        />
        <path
          id="bottom-hamburg"
          d="M0 49H48.5"
          stroke="#F7F7F7"
          stroke-width="3"
        />
      </svg>
  )

  const popUpNav = (
    <div className="popUpList">
      <div className="popUpContainer">
        <Link onClick={() => setNavpopConcerts(!navpopConcerts)} className="navLink" to="/">
          Home
        </Link>
        <Link
          onClick={() => setNavpopConcerts(!navpopConcerts)}
          className="navLink"
          to="/Concerts"
        >
          Concerts{" "}
        </Link>
        <Link
          onClick={() => setNavpopConcerts(!navpopConcerts)}
          classname="navLink"
          to="/Portraits"
        >
          Portraits
        </Link>
        <Link
          onClick={() => setNavpopConcerts(!navpopConcerts)}
          classname="navLink"
          to="/Weddings"
        >
          Weddings
        </Link>
        <Link
          onClick={() => setNavpopConcerts(!navpopConcerts)}
          classname="navLink"
          to="/Travel"
        >
          Travel
        </Link>
        <Link
          onClick={() => setNavpopConcerts(!navpopConcerts)}
          to="/Contact"
          style={{ color: "#fbc89a" }}
        >
          Contact
        </Link>
      </div>
    </div>
  )
  const closeIcon = (
    <svg
      className="closeIcon"
      onClick={() => setNavpopConcerts(!navpopConcerts)}
      viewBox="0 0 50 50"
      width="50"
      height="25"
      overflow="visible"
      stroke-width="6"
      stroke-linecap="round"
    >
      <line x1="0" y1="0" x2="50" y2="50" />
      <line x1="50" y1="0" x2="0" y2="50" />
    </svg>
  )
  return (
    <div className="secondary-page">
      <div className="secondary-top-section">
        <h1>Concerts</h1>
      </div>
      <div className="gallery">
        <div className="row">
          <div className="column">
            <StaticImage
              className="gallery-image"
              src="../images/concert-1G6A0127.JPG"
              alt="A dinosaur"
              placeholder="blurred"
              quality={90}
              loading="lazy"
            />
          </div>
        </div>

        <div className="row">
          <div className="column">
            <StaticImage
              className="gallery-image"
              src="../images/concert-1G6A2905.JPG"
              alt="A dinosaur"
              placeholder="blurred"
              quality={90}
              loading="lazy"
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <StaticImage
              className="gallery-image"
              src="../images/concert-1G6A0506-3.JPG"
              alt="A dinosaur"
              placeholder="blurred"
              quality={90}
              loading="lazy"
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <StaticImage
              className="gallery-image"
              src="../images/concert-1G6A2116.JPG"
              alt="A dinosaur"
              placeholder="blurred"
              quality={90}
              loading="lazy"
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <StaticImage
              className="gallery-image"
              src="../images/concert-1G6A3204.JPG"
              alt="A dinosaur"
              placeholder="blurred"
              quality={90}
              loading="lazy"
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <StaticImage
              className="gallery-image"
              src="../images/concert-1G6A9530.JPG"
              alt="A dinosaur"
              placeholder="blurred"
              quality={90}
              loading="lazy"
            />
          </div>
        </div>
      </div>
        {navpopConcerts ? closeIcon : hamburgSecondary}
        {navpopConcerts ? popUpNav : ""}
    </div>
  )
}
export default Concerts
